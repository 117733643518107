@import "../../../../../assets/scss/variables.scss";
@import "../../../../../assets/scss/mixins.scss";

.product-container {
  display: block;
  width: 100%;
  padding-bottom: 50px;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(221, 224, 228);
  color: rgb(25, 31, 40);
  min-height: 400px;
  padding: 0 16px;
  box-sizing: border-box;

  .product-info {
    width: 100%;
    max-width: 1136px;
    margin: 0px auto;
    margin-top: -156px;
    padding-top: 32px;
    box-sizing: border-box;

    .header-info {
      width: 100%;
      margin: 0px auto;
      box-sizing: border-box;

      .container-header {
        position: relative;
        height: 100%;
        padding: 12px 16px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid rgb(221, 224, 228);
        background-color: rgb(245, 247, 248);
        display: flex;
        flex-flow: row;
        align-items: center;
        flex-direction: column;

        .title {
          color: rgb(25, 31, 40);
          font-size: 17px;
          width: 100%;
          margin: 0;
          padding-bottom: 20px;
          font-family: $roboto_bold;
          text-align: center;

          @include breakpoint(tablet-large) {
            font-size: 23px;
          }
        }

        .description {
          width: 100%;
          display: grid;
          gap: 40px;
          align-items: center;
          padding: 16px;
          background-color: rgb(255, 255, 255);
          border-radius: 8px;
          box-sizing: border-box;

          @include breakpoint(tablet-large) {
            padding: 24px;
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 40px;
            align-items: center;
          }

          .image-pix {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid rgb(221, 224, 228);
            border-radius: 8px;

            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: 12px;
              margin-bottom: 12px;
              border-bottom: 2px solid rgb(221, 224, 228);
            }

            img {
              width: 180px;
              height: 180px;
              margin-bottom: 16px;
            }

            .copy-paste {
              margin: 0;
              color: rgb(0, 151, 255);
              font-size: 12px;
              font-weight: 700;
              word-break: break-all;
              text-align: center;
              width: 100%;
              cursor: pointer;
            }

            .copy-button {
              border-radius: 8px;
              outline: none;
              border-color: transparent;
              border-width: 1px;
              border-style: solid;
              font-size: 14px;
              font-family: $roboto_bold;
              line-height: 16px;
              letter-spacing: 1px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              min-width: fit-content;
              width: 100%;
              cursor: pointer;
              transition: 0.2s ease-in;
              text-decoration: none;
              background-color: rgb(0, 151, 255);
              color: rgb(255, 255, 255);
              padding: 12px 24px;
              flex-direction: row;
              text-transform: uppercase;
              transition: all 0.1s ease-in;

              &.copied {
                background-color: rgb(81, 168, 0);
                border-color: rgb(81, 168, 0);

                &:hover {
                  background-color: rgb(98, 190, 39);
                  border-color: rgb(98, 190, 39);
                }
              }

              &:hover {
                background-color: rgb(58, 175, 255);
                border-color: rgb(58, 175, 255);
              }

              svg {
                height: 24px;
                width: 24px;
                fill: rgb(255, 255, 255);
                margin-right: 8px;
              }
            }
          }

          .pending-pix {
            display: flex;
            flex-direction: column;
            height: 100%;

            p {
              line-height: 1.5;
              color: rgb(76, 87, 108);
              margin: 0;
            }

            .pending-pix-header {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 16px;
              padding: 32px 0px;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              .title-header {
                font-family: $roboto_bold;
                font-size: 18px;
              }

              .info-pending {
                display: flex;
                align-items: center;
                gap: 12px;

                svg {
                  min-width: 20px;
                }

                p {
                  font-size: 14px;
                  font-family: $roboto_regular;

                  @include breakpoint(tablet-large) {
                    font-size: 18px;
                  }
                }
              }
            }

            .pending-pix-time {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 32px 0px;
              border-top: 1px solid rgb(221, 224, 228);
              gap: 5px;

              @include breakpoint(tablet-large) {
                flex-direction: row;
              }

              p {
                font-size: 15px;
                color: rgb(76, 87, 108);
                line-height: 1.5;
                font-family: $roboto_regular;

                @include breakpoint(tablet-large) {
                  font-size: 18px;
                }
              }

              span {
                color: rgb(255, 106, 0);
                font-size: 18px;
                font-family: $roboto_bold;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .helper-pix {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        font-family: $roboto_bold;
        font-size: 17px;
        letter-spacing: 0.4px;
        color: rgb(25, 31, 40);

        @include breakpoint(tablet-large) {
          font-size: 20px;
        }
      }

      .container-helper {
        width: 100%;
        margin-top: 10px;
        padding-bottom: 32px;
      }

      .info-event {
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        box-shadow: rgba(25, 31, 40, 0.1) 0px 2px 10px 0px;
        flex-direction: column;
        gap: 20px;

        @include breakpoint(tablet-large) {
          padding: 24px;
          gap: 0;
          flex-direction: row;
        }

        .image-container {
          border-radius: 8px;
          height: 140px;
          width: 100%;
          overflow: hidden;
          margin-right: 32px;

          @include breakpoint(tablet-large) {
            width: 308px;
          }

          img {
            height: 140px;
            width: 100%;
            object-fit: cover;
            object-position: center center;

            @include breakpoint(tablet-large) {
              width: 308px;
            }
          }
        }

        .data-event {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            margin: 0;
            font-family: $roboto_bold;
            font-size: 20px;
            letter-spacing: 0.4px;
            color: rgb(25, 31, 40);
          }

          .event-information-container {
            position: relative;
            display: flex;
            align-items: flex-start;
            overflow: hidden;
            margin-top: 8px;

            div {
              display: flex;
              flex-direction: column;
              width: 100%;
              overflow: hidden;
              flex-wrap: wrap;

              .title {
                font-family: $roboto_regular;
                font-size: 14px;
                line-height: 1.33;
                color: black;
                margin: 0 0 5px 0;
                width: 100%;
                text-align: left;

                @include breakpoint(tablet-large) {
                  font-size: 15px;
                }
              }

              .info {
                font-family: $roboto_light;
                font-size: 14px;
                line-height: 1.43;
                color: black;
                width: 100%;
                margin: 0;
                text-align: left;
              }
            }

            .mdi--clock-outline {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 0.5rem;
              --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z'/%3E%3C/svg%3E");
              background-color: currentColor;
              -webkit-mask-image: var(--svg);
              mask-image: var(--svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
            }

            .carbon--location {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 0.5rem;
              --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000' d='M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3'/%3E%3Cpath fill='%23000' d='m16 30l-8.436-9.949a35 35 0 0 1-.348-.451A10.9 10.9 0 0 1 5 13a11 11 0 0 1 22 0a10.9 10.9 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 1 0-18 0a8.9 8.9 0 0 0 1.813 5.395'/%3E%3C/svg%3E");
              background-color: currentColor;
              -webkit-mask-image: var(--svg);
              mask-image: var(--svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
            }
          }

          button {
            align-items: center;
            background-color: white;
            border-radius: 8px;
            border: 2px solid rgb(0, 151, 255);
            box-sizing: border-box;
            color: rgb(0, 151, 255);
            cursor: pointer;
            display: flex;
            font-family: $roboto_bold;
            font-size: 16px;
            gap: 10px;
            padding: 8px 16px;
            margin-top: 5px;
            transition: all 0.3s ease-in;
            width: max-content;
            z-index: 4;

            &:hover {
              color: white;
              background-color: rgb(58, 175, 255);
              border: 2px solid rgb(58, 175, 255);

              svg {
                path {
                  fill: white;
                }
              }
            }

            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }
}
