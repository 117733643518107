@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.date-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  @include breakpoint(tablet-large) {
    width: 120px;
  }

  .title {
    font-family: $hanken_bold;
    color: rgb(76, 87, 108);
    text-wrap: nowrap;
    margin-bottom: 5px;
    font-size: 14px;
    margin: 0;

    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .input {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    padding-left: 15px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    color: rgb(25, 31, 40);
    font-family: $hanken_regular;
    border: 2px solid #e6e6f2;

    &.error {
      border-color: #e74c3c;
      color: rgb(25, 31, 40);
    }

    &:focus {
      border-color: rgba(0, 153, 255, 0.749);
      outline: none;
    }
  }

  .error {
    display: block;
    font-size: 12px;
    color: #e74c3c;
  }
}
