@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: visibility 0s ease 0.3s;
  animation: modalFadeOut 0.3s ease forwards;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    animation: modalFadeIn 0.3s ease forwards;
  }

  @include breakpoint(tablet) {
    width: 80%;
    height: fit-content;
    max-width: 500px;
  }

  p {
    padding: 10px 0 0 10px;
    font-size: 20px;
    font-family: $roboto_bold;
  }

  .image-modal {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    padding: 0 10px 0 0;
    cursor: pointer;
  }

  .share-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    gap: 10px;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    button {
      gap: 12px;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      padding: 12px;
      font-family: $roboto_regular;
      font-weight: 300;
      font-size: 16px;
      color: rgb(76, 87, 108);
      cursor: pointer;
      width: 100%;
      transition: background-color 0.3s ease;
      border-bottom: 1px solid rgb(221, 224, 228);

      @include breakpoint(tablet) {
        border-bottom: unset;
      }

      svg {
        filter: invert(41%) sepia(83%) saturate(2915%) hue-rotate(185deg)
          brightness(104%) contrast(105%);
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: rgb(245, 247, 248);
      }
    }
  }
}
