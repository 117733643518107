@import "../assets/scss/variables.scss";

html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: $hanken_regular;

  .ant-layout {
    background: #FFF9F0 !important;
  }
}
