// .questions-container {
//   width: 100%;
// }

// .question-card-container {
//   margin: 40px 0;
// }

.ant-card-body {
  padding: 0; 

  .select-type {
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-start;

    .ant-form-item {
      margin: 0;
    }

    .delete-button {
      display: flex;
      width: 38px;
      height: 37px;
      padding: 8px 14px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      // gap: 10px;
      border-radius: 50%;
      border: 1px solid  #ff4d4f;

      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.divider-modal {
  border-top: 4px dashed #cbcbcb;
  padding: 0;
  margin: 50px 0;
  position: relative;
  width: 100%;
  background-color: white;
}


// .question-card {
//   margin-top: 30px;
//   display: flex;
//   align-items: flex-end;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   background-color: #fff;
// }

// .question-input {
//   width: 90%;
//   padding: 8px;
//   margin-right: 20px;
// }

// .select-container {
//   margin-right: 30px;
// }

// select {
//   padding: 8px;
//   border-radius: 4px;
//   border: 1px solid #ccc;
// }

// .delete-button {
//   background-color: red;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 4px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
// }

// .options-container {
//   border-radius: 0 0 20px 20px;
//   padding: 20px;
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   border: 2px solid black;
//   border-top: none;
// }

// .input-group {
//   width: 300px;
// }

// input[type="text"] {
//   width: 100%;
//   padding: 8px;
//   margin-bottom: 10px;
//   border-radius: 4px;
//   border: 1px solid #ccc;
// }

// .buttons-group {
//   width: 80%;
//   display: flex;
//   flex-wrap: wrap;
// }

// .option-button {
//   text-transform: none;
//   min-width: 100px;
//   height: 50px;
//   margin: 20px;
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// }
