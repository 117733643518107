@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.ResponseTicket {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;

  .container-header {
    display: inline-block;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 2px solid rgb(221, 224, 228);
    color: rgb(25, 31, 40);

    .response-header {
      width: 100%;
      max-width: 1200px;
      margin: 0px auto;
      box-sizing: border-box;
      padding: 0px 16px 140px;

      @include breakpoint(tablet-large) {
        padding: 16px 32px 140px;
      }

      .infos-header {
        padding: 32px 0;

        h2 {
          font-size: 25px;
          color: rgb(25, 31, 40);
          font-family: $roboto_bold;
          margin: 0;

          @include breakpoint(tablet-large) {
            font-size: 45px;
          }
        }

        .container-infos {
          display: flex;
          justify-content: space-between;
          margin-top: 32px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          @include breakpoint(mobile-larger) {
            align-items: center;
            gap: 0;
            flex-direction: row;
          }

          .info {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            flex-direction: column;

            @include breakpoint(tablet-large) {
              align-items: flex-end;
              flex-direction: row;
            }

            p {
              color: rgb(76, 87, 108);
              font-size: 12px;
              font-family: $roboto_regular;
              text-transform: uppercase;
              margin: 0;

              &:last-child {
                color: rgb(25, 31, 40);
                font-size: 15px;
                font-family: $roboto_bold;

                @include breakpoint(tablet-large) {
                  font-size: 20px;
                }
              }
            }
          }

          .info-proccess {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.free {
              svg {
                fill: rgb(98, 190, 39);
              }

              p {
                color: rgb(98, 190, 39);
              }
            }

            &.expired {
              svg {
                fill: red;
              }
              p {
                color: red;
              }
            }

            svg {
              fill: rgb(255, 144, 54);
              margin-right: 8px;
              width: 24px;
              height: 24px;

              @include breakpoint(tablet-large) {
                width: 40px;
                height: 40px;
              }
            }

            p {
              color: rgb(255, 144, 54);
              font-size: 15px;
              font-family: $roboto_bold;
              line-height: 1.5;
              letter-spacing: 1px;
              margin: 0;

              @include breakpoint(tablet-large) {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }

  .container-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    justify-content: center;

    svg {
      width: 100px;
      height: 100px;
    }

    p {
      font-family: $roboto_regular;
      font-size: 25px;
      color: rgb(98, 190, 39);
      margin: 20px 0 0 0;
    }
  }
}
