@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.TicketSelector {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;

  .header-event {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    min-width: 320px;
    background-color: rgb(255, 255, 255);
    position: relative;
    background-image: url("");
    background-position: 50% 50%;
    background-size: 120%;
    height: 30vh;
    max-height: 435px;
    min-height: 168px;
    margin-bottom: 0px;

    @include breakpoint(tablet-large) {
      height: 50vh;
    }

    @include breakpoint(desktop-small) {
      height: 64vh;
      max-height: 515px;
      min-height: 460px;
      margin-bottom: 48px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(24px) brightness(0.8);
    }

    .header-image-container {
      padding: 0px 2rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1100px;
      position: relative;
      padding: 0;
      margin-top: 0;
      height: 100%;

      @include breakpoint(tablet-large) {
        padding: 0px 2rem;
        margin-top: -8px;
        height: 109%;
      }

      @include breakpoint(desktop-small) {
        padding: 0px 2rem;
      }

      .header-image {
        position: relative;
        display: flex;
        align-items: flex-end;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        z-index: 1;
        width: 100%;
        margin: 0px auto;
        border-radius: 0;
        background-image: url("");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;

        @include breakpoint(tablet-large) {
          border-radius: 12px;
          box-shadow: rgba(25, 31, 40, 0.2) 0px 20px 36px 0px;
        }

        button {
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 4;
          gap: 10px;
          border-radius: 1000vw;
          text-transform: uppercase;
          background-color: rgb(255, 255, 255);
          color: rgb(0, 151, 255);
          width: max-content;
          font-size: 16px;
          padding: 8px 16px;
          border-color: transparent;
          font-weight: 600;
          font-family: $roboto_regular;
          transition: all 0.3s ease-in;
          margin-bottom: -42px;
          display: none;

          @include breakpoint(tablet-large) {
            display: flex;
            margin-bottom: calc(-21px);
          }

          &:hover {
            background-color: rgb(230, 244, 255);
            border-color: rgb(58, 175, 255);
          }

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .header-disabled {
        z-index: 2;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        min-height: 42px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(25, 31, 40, 0.15) 0px 6px 24px 0px;

        @include breakpoint(tablet-large) {
          display: none;
        }

        button {
          display: flex;
          align-items: center;
          z-index: 8;
          gap: 10px;
          border-radius: 1000vw;
          text-transform: uppercase;
          background-color: rgb(255, 255, 255);
          color: rgb(0, 151, 255);
          width: max-content;
          font-size: 16px;
          padding: 8px 16px;
          border-color: transparent;
          min-width: fit-content;
          font-weight: 600;
          height: min-content;
          font-family: $roboto_regular;
          transition: all 0.3s ease-in;
          margin-bottom: -42px;
          height: 100%;

          @include breakpoint(tablet-large) {
            margin-bottom: calc(-21px);
          }

          &:hover {
            background-color: rgb(230, 244, 255);
            border-color: rgb(58, 175, 255);
          }

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }

  .event-section {
    background: #fff;
    position: relative;
    padding: 30px 0 0 0;
    min-height: 400px;

    @include breakpoint(tablet-large) {
      padding: 40px 0 0 0;
    }

    .summary {
      max-width: 1100px;
      padding-bottom: 40px;
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      box-sizing: border-box;

      .info-summary {
        width: 100%;

        @include breakpoint(tablet-large) {
          // width: calc(100% - 380px);
        }
      }

      @include breakpoint(tablet-large) {
        padding: 0 2rem 40px 2rem;
      }

      @include breakpoint(desktop-medium) {
        padding: 0 0 40px 0;
      }

      .tag {
        margin: 0 0 14px 0;
        border-width: 1px;
        border-style: solid;
        border-radius: 24px;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        line-height: 1;
        background-color: rgb(238, 246, 230);
        color: rgb(81, 168, 0);
        border-color: rgb(238, 246, 230);
        padding: 4px 12px;
        font-size: 12px;
        height: 20px;
        max-width: fit-content;
        align-self: auto;

        .ion--card-outline {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Crect width='416' height='320' x='48' y='96' fill='none' stroke='rgb(81, 168, 0)' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' rx='56' ry='56'/%3E%3Cpath fill='none' stroke='rgb(81, 168, 0)' stroke-linejoin='round' stroke-width='60' d='M48 192h416M128 300h48v20h-48z'/%3E%3C/svg%3E");
        }
        p {
          font-family: $roboto_regular;
          color: rgb(81, 168, 0);
          font-size: 15px;
          margin: 0;
        }
      }

      .ticket-section {
        width: 100%;
        // right: 35px;
        margin-top: 20px;
        z-index: 1;
        top: 0;
        box-shadow: rgb(221, 224, 228) 0px 6px 24px;
        border-radius: 12px;
        background-color: rgb(255, 255, 255);
        right: 0;

        @include breakpoint(tablet-large) {
          width: 340px;
          margin-top: 0;
          position: absolute;
          // right: 0;
        }

        @include breakpoint(desktop-medium) {
          right: 0;
        }

        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          background-color: rgb(76, 87, 108);
          color: rgb(255, 255, 255);
          display: flex;
          border-radius: 12px 12px 0px 0px;
          -webkit-box-pack: justify;
          justify-content: space-between;
          padding: 12px;
          line-height: 24px;

          p {
            font-size: 16px;
            font-family: $roboto_regular;
            letter-spacing: 1px;
            margin: 0;
          }

          .price-info {
            font-size: 16px;
            font-family: $roboto_bold;
            margin: 0;
          }
        }

        .ticket-item {
          padding: 8px;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          font-size: 12px;
          border-width: 0px 0px 1px;
          border-style: solid;
          border-color: rgb(221, 224, 228);

          .ticket-details {
            p {
              line-height: 1.5;
            }

            .ticket-title {
              font-family: $roboto_bold;
              font-size: 15px;
              line-height: 16px;
              margin: 0;
            }

            .price {
              font-size: 15px;
              font-family: $roboto_regular;
              margin: 0;
            }

            .installments {
              font-size: 14px;
              font-family: $roboto_bold;
              margin: 0;
              color: rgb(81, 168, 0);
            }

            .validity {
              font-style: italic;
              color: rgb(132, 140, 155);
              font-size: 14px;
              font-family: $roboto_regular;
              margin: 0;
            }
          }

          .quantity-controls {
            display: flex;
            align-items: center;

            .decrease-btn,
            .increase-btn {
              background-color: transparent;
              border: none;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.disabled {
                svg {
                  path {
                    fill: rgb(221, 224, 228);
                  }
                }
              }

              svg {
                path {
                  fill: rgb(0, 151, 255);
                }
              }
            }

            .decrease-btn:disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }

            .quantity {
              font-size: 15px;
              font-family: $roboto_bold;
            }
          }
        }

        .purchase-button {
          padding: 8px;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          box-sizing: border-box;

          button {
            border: none;
            padding: 10px 20px;
            border-radius: 4px;
            font-family: $roboto_bold;
            cursor: pointer;
            font-size: 17px;
            width: 100%;
            background-color: rgb(221, 224, 228);
            color: rgb(132, 140, 155);
            cursor: default;
            pointer-events: none;
          }
        }

        .promo-button {
          margin-top: 20px;

          button {
            background-color: #007bff;
            border: none;
            color: #fff;
            padding: 10px 20px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 1rem;

            &:hover {
              background-color: #0056b3;
            }
          }
        }

        .total-summary {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .total-details {
            padding: 8px;
            flex: 0.7 1 0%;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
            p {
              font-size: 13px;
              color: rgb(189, 194, 201);
              white-space: nowrap;
              font-family: $roboto_bold;
              margin: 0;
            }

            .total-price {
              font-weight: bold;
              font-size: 16px;
              color: black;
              font-family: $roboto_bold;
              margin: 0;
            }

            .total-installments {
              font-family: $roboto_bold;
              font-size: 14px;
              font-weight: bold;
              color: rgb(98, 190, 39);
            }
          }
        }

        .open-button {
          padding: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            border: none;
            padding: 10px 15px;
            border-radius: 4px;
            font-family: $roboto_bold;
            font-size: 15px;
            background-color: rgb(81, 168, 0);
            color: white;
            cursor: pointer;
            pointer-events: auto;
            transition: all 0.3s ease-in-out;

            &:hover {
              background-color: rgb(98, 190, 39);
              border-color: rgb(98, 190, 39);
            }
          }
        }
      }

      h1 {
        font-family: $roboto_bold;
        font-size: 40px;
        min-height: 35px;
        text-overflow: ellipsis;
        letter-spacing: 0.36px;
        margin: 0 0 10px 0;
        color: black;
      }

      .event-information-container {
        position: relative;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        margin-top: 8px;

        div {
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: hidden;
          flex-wrap: wrap;

          .title {
            font-family: $roboto_regular;
            font-size: 14px;
            line-height: 1.33;
            color: black;
            margin: 0 0 5px 0;
            width: 100%;
            text-align: left;

            @include breakpoint(tablet-large) {
              font-size: 18px;
            }
          }

          .info {
            font-family: $roboto_light;
            font-size: 14px;
            line-height: 1.43;
            color: black;
            width: 100%;
            margin: 0;
            text-align: left;
          }
        }

        .mdi--clock-outline {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }

        .carbon--location {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000' d='M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3'/%3E%3Cpath fill='%23000' d='m16 30l-8.436-9.949a35 35 0 0 1-.348-.451A10.9 10.9 0 0 1 5 13a11 11 0 0 1 22 0a10.9 10.9 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 1 0-18 0a8.9 8.9 0 0 0 1.813 5.395'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }
      }
    }

    .divider {
      height: 1px;
      background-color: #d3d3d3;
      width: 100%;
      margin-top: 35px;
    }
  }

  .event-payment {
    min-height: 400px;
    background-color: #f3f3f3;

    .ticket-description {
      max-width: 1100px;
      margin: 0 auto;
      padding: 15px 0 200px 0;
      min-height: 400px;

      .description {
        padding: 2rem;

        @include breakpoint(tablet-large) {
          width: calc(100% - 380px);
        }

        @include breakpoint(desktop-small) {
          padding: 0;
        }

        .render-html {
          width: 100%;
          overflow: hidden;
        }

        .title {
          font-size: 28px;
          font-family: $roboto_bold;
        }

        .address {
          &.subtitle {
            font-size: 20px;
            font-family: $roboto_regular;
            margin: 0;
            text-transform: uppercase;

            @include breakpoint(tablet-large) {
              font-size: 25px;
            }
          }

          &.info {
            margin: 5px 0;
            font-family: $roboto_light;
            font-size: 15px;

            @include breakpoint(tablet-large) {
              font-size: 20px;
            }
          }
        }

        .location-button {
          display: flex;
          padding: 8px 12px;
          margin-top: 10px;
          align-items: center;
          background-color: transparent;
          color: rgb(0, 151, 255);
          border: 1px solid rgb(0, 151, 255);
          border-width: 1px;
          outline: none;
          transition: 0.2s ease-in;
          line-height: 14px;
          font-size: 14px;
          font-family: $roboto_bold;
          border-radius: 8px;
          text-decoration: none;
          box-shadow: unset;
          width: fit-content;

          @include breakpoint(tablet-large) {
            font-size: 18px;
          }

          &:hover {
            background-color: rgb(58, 175, 255);
            border-color: rgb(58, 175, 255);
            color: white;

            svg {
              fill: rgb(255, 255, 255);
            }
          }

          svg {
            margin-right: 4px;
            fill: rgb(0, 151, 255);
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .event-card {
      max-width: 1100px;
      margin: 0 auto;
      padding: 15px 0 200px 0;
      min-height: 400px;

      .product-details {
        border-radius: 2px;
        border: 1px solid #d6d8db;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
        margin: 25px 0;
        min-height: 100px;
        max-width: 560px;

        .title-product {
          align-items: center;
          border-radius: 13px 13px 0 0;
          display: flex;
          justify-content: space-between;
          min-height: 20px;
          padding: 10px 25px;

          p {
            color: black;
            font-family: $roboto_bold;
            font-size: 14px;
            margin: 0;
            text-align: left;
          }
        }

        .sub-details-product {
          display: flex;
          justify-content: space-between;
          padding: 0 25px;

          .infos-product {
            p {
              color: grey;
              font-family: $roboto_regular;
              font-size: 16px;
              margin: 7px 0;
              text-align: left;

              &.green {
                color: #00a832;
              }

              &:last-child {
                font-style: italic;
                color: #0097ff;
              }
            }
          }

          .increment-product {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .contain-product {
              align-items: center;
              display: flex;
              justify-content: space-between;

              p {
                font-family: $roboto_regular;
                font-size: 16px;
                margin: 0 10px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .accept-product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: var(--background-inverse-color, #ffffff);
        border-radius: 4px;
        color: var(--text-color, #000000);
        padding: 20px;
        min-height: 104px;

        .infos-accept {
          font-family: $roboto_bold;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          color: black;

          label {
            display: flex;
            align-items: center;
            height: 20px;

            input[type="checkbox"] {
              appearance: none;
              -webkit-appearance: none;
              height: 20px;
              width: 20px;
              border-radius: 2px;
              border: 2px solid black;
              margin-right: 10px;
              position: relative;
              background-color: white;
              cursor: pointer;

              &:checked {
                background-color: #2196f3;
                border-color: #2196f3;

                &::after {
                  content: "";
                  position: absolute;
                  top: 2px;
                  left: 5px;
                  width: 4px;
                  height: 8px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }
              }
            }
          }

          p {
            font-size: 18px;
            margin: 0;
          }
        }

        button {
          border-radius: 3px;
          font-size: 19px;
          border: unset;
          font-family: $roboto_bold;
          color: white;
          outline: unset;
          box-shadow: unset;
          cursor: pointer;
          background-color: #58c22e;
          padding: 15px 25px;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: #0096ff;
          }

          &:disabled {
            background-color: #d6d8db;
            cursor: unset;
          }
        }
      }
    }
  }
}
