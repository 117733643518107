@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.EventCheckoutDetails {
  .container-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    display: flex;
    flex-direction: column;

    .ant-form-item {
      .ant-form-item-row {
        flex-direction: column !important;
        height: max-content;
        display: flex;

        .ant-col {
          width: 100% !important;
          display: flex;
          flex: 0 0 0 !important;
          flex-grow: unset !important;
        }
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .container-left,
    .container-right {
      width: 100%;

      @include breakpoint(tablet) {
        width: 50%;
      }
    }

    .container-right {
      // gap: 30px;
      display: flex;
      flex-direction: column;
    }

    div {
      .info-date {
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #d9d9d9;
        border-radius: 6px;
        border-style: solid;
        border-width: 1px;
        box-shadow: none;
        color: #71748d;
        cursor: not-allowed;
        margin: 0;
        opacity: 1;
        padding: 5px 11px;
      }

      input,
      .ant-select,
      .ant-picker,
      .ant-input-number,
      .info-date {
        width: 100%;
        font-size: 15px;
        color: #71748d;
        height: 40px;
        border-radius: unset;
      }
    }
  }
}

.download-section {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
