@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.EventPay {
  .breadcrumb-title {
    span {
      color: rgba(0, 0, 0, 0.45) !important;
      font-size: pxToRem(15px) !important;
      font-family: $hanken_regular !important;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px) !important;
      }
    }
  }

  .container-table {
    .box-filters {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 30px 0;

      .ant-select-selector {
        height: 32px;
      }

      @include breakpoint(desktop-small) {
        align-items: center;
        flex-direction: row;
        height: 32px;
      }
    }

    .status-color {
      border-radius: 2px;
      border: 1px solid white;
      font-size: pxToRem(13px);
      font-family: $hanken_regular;
      display: inline-flex;
      padding: 1px 8px;
      align-items: center;
      box-sizing: border-box;

      &.Gratuito {
        color: #1890ff;
        border-color: #91d5ff;
        background-color: #e6f7ff;
      }

      &.Planejado {
        color: #722ed1;
        border-color: #d3adf7;
        background-color: #f9f0ff;
      }

      &.Pago {
        color: #52c41a;
        border-color: #b7eb8f;
        background-color: #f6ffed;
      }

      &.Pendente {
        color: #faad14;
        border-color: #ffe58f;
        background-color: #fffbe6;
      }

      &.Expirado,
      &.Estornado {
        color: #f5222d;
        border-color: #ffa39e;
        background-color: #fff1f0;
      }
    }
  }
}
