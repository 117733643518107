@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.PanelLayout {
  height: 100vh;
  overflow-y: unset;
  position: relative;

  header {
    background: #fff;
    box-shadow: 0px 4px 10.4px 0px #e0dcda;
  }

  .ant-menu-item {
    font-family: $hanken_regular;
  }
  .ant-menu-item-selected {
    background-color: #fff9b6;
    color: black;
    font-family: $hanken_regular;

    svg {
      path {
        fill: black;
      }
    }
  }

  aside {
    padding-top: 20px;

    @include breakpoint(desktop-small) {
      padding-top: 45px;
    }

    .ant-layout-sider-children {
      padding: 0 10px;
      box-sizing: border-box;
    }

    .menu-click {
      cursor: pointer;
      height: 50px;
      margin: 4px;
      padding-left: 16px;
      width: 50px;

      &.active {
        padding-left: 20px;
      }

      svg {
        width: 24px;
        height: 24px;
        

        path {
          fill: #ff9d00;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .ant-layout-sider {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;

    img {
      height: 50px;
    }
  }

  .ant-layout-sider-children {
    // width: 100% !important;
    // max-width: 100% !important;

    @include breakpoint(desktop-small) {
      position: static !important;
      display: block !important;
    }
  }

  .teste,
  .ant-layout-sider-children {
    // width: 100% !important;
    // max-width: 100% !important;

    @include breakpoint(desktop-small) {
      // width: 250px !important;
      max-width: 250px !important;
    }
  }
  .ant-layout-header {
    padding: 0 0 0 20px;
  }

  .right-buttons {
    display: flex;
    height: 100%;
    flex-direction: row-reverse;

    @include breakpoint(desktop-small) {
      flex-direction: row;
    }

    button {
      height: 100%;
      border-radius: 0;
      // border: 1px solid red;
      padding: 13px 20px;
      font-size: 16px;
      border-right: 1px solid #e6e6f2;
      border-left: 1px solid #e6e6f2;

      &:last-child {
        border: unset;
        position: relative;

        .container-user {
          position: absolute;
          right: 0;
          top: 65px;
          display: flex;
          cursor: initial;
          flex-direction: column;
          align-items: center;
          width: 230px;
          padding: 0;
          box-sizing: border-box;
          background: white;
          border-left: 1px solid #e6e6f2;
          border-radius: 0 4px 4px 0;

          .header-user {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #ff9d00;
            color: white;
            width: 100%;
            padding: 10px;

            span {
              font-size: 16px;
              font-weight: bold;
              text-transform: capitalize;
              margin: 0;
            }

            p {
              font-size: 14px;
              margin: 0 0 0 10px;
            }
          }

          .container-buttons-user {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            button {
              border: none;
              width: 100%;
              text-align: left;
              padding: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &:hover {
                background-color: #f7f7fb;
              }
            }
          }
        }
      }
    }
  }
}
