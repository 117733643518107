@import "./fonts";

$hanken_light: HankenGrotesk-Light, sans-serif;
$hanken_bold: HankenGrotesk-Bold, sans-serif;
$hanken_regular: HankenGrotesk-Regular, sans-serif;
$hanken_thin: HankenGrotesk-Thin, sans-serif;
$hanken_medium: HankenGrotesk-Medium, sans-serif;
$hanken_italic: HankenGrotesk-Italic, sans-serif;

$roboto_light: Roboto-Light, sans-serif;
$roboto_bold: Roboto-Bold, sans-serif;
$roboto_regular: Roboto-Regular, sans-serif;
$roboto_thin: Roboto-Thin, sans-serif;
$roboto_medium: Roboto-Medium, sans-serif;
$roboto_italic: Roboto-Italic, sans-serif;

$josefinsans_light: JosefinSans-Light, sans-serif;
$josefinsans_bold: JosefinSans-Bold, sans-serif;
$josefinsans_regular: JosefinSans-Regular, sans-serif;

$breakpoints: (
  "mobile-medium": 400px,
  "mobile-larger": 576px,
  "tablet": 768px,
  "tablet-large": 992px,
  "desktop-small": 1024px,
  "desktop-medium": 1440px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px,
  "4k": 2300px,
);
