@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.Account {
  .content-dashboard {
    padding: 23px 10px 70px 10px;

    @include breakpoint(desktop-medium) {
      padding: 23px 32px 100px 32px;
    }
  }
  .breadcrumb-title {
    span {
      color: rgba(0, 0, 0, 0.45) !important;
      font-size: pxToRem(15px) !important;
      font-family: $hanken_regular !important;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px) !important;
      }
    }
  }

  .container-account {
    padding: 40px 0px 0;
    box-sizing: border-box;

    @include breakpoint(desktop-medium) {
      padding: 107px 50px 0;
    }

    .top-account {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;

      @include breakpoint(desktop-small) {
        align-items: center;
        flex-direction: row;
      }

      .card-balance {
        border-radius: 16px;
        background: #f5f5f5;
        display: flex;
        max-width: 377px;
        width: 100%;
        padding: 16px 16px 16px 24px;
        flex-direction: column;
        align-items: flex-start;

        span {
          color: #595959;
          font-family: $hanken_regular;
          font-size: pxToRem(16px);
          font-weight: 500;
        }

        .value-balance {
          display: flex;
          gap: 10px;
          height: 40px;

          .balance-container {
            position: relative;
            display: inline-block;
            height: 34px;

            .money {
              color: #389e0d;
              font-family: $hanken_bold;
              font-size: pxToRem(32px);
              top: 0;
              left: 0;
              width: 100%;
              margin: 0;

              &.hidden {
                filter: blur(10px);
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 5px;
              }
            }

            .empty-money {
              color: grey;
              font-size: pxToRem(30px);
              margin: 0;
            }
          }

          svg {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }

      .money-transfer {
        align-items: center;
        background: #389e0d;
        border-radius: 8px;
        border: unset;
        box-shadow: none;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        font-family: $hanken_regular;
        font-size: pxToRem(20px);
        gap: 8px;
        height: 48px;
        justify-content: center;
        line-height: 22px;
        padding: 4px 15px;
        text-align: center;

        svg {
          width: 15px;
          height: 15px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .account-body {
      margin-top: 75px;

      .account-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;

        @include breakpoint(desktop-small) {
          flex-direction: row;
          margin-bottom: 40px;
        }

        .title {
          display: flex;
          align-items: flex-start;
          h2 {
            color: #8c8c8c;
            font-family: $hanken_light;
            font-size: pxToRem(20px);
            margin: 0;
          }
        }

        .filters-div {
          display: flex;
          gap: 20px;
          flex-direction: column;
          margin: 20px 0 40px 0;

          @include breakpoint(desktop-small) {
            flex-direction: row;
            margin: 0;
          }
        }

        .ant-select-selector,
        .ant-select {
          height: 32px;
          margin: 0 !important;
        }

        .ant-input-group-wrapper,
        .ant-input-wrapper,
        input {
          height: 32px;
          margin: 0 !important;
        }
      }

      .status-color {
        border-radius: 2px;
        border: 1px solid white;
        font-size: pxToRem(13px);
        font-family: $hanken_regular;
        display: inline-flex;
        padding: 1px 8px;
        align-items: center;
        box-sizing: border-box;

        &.Agendado {
          color: #1890ff;
          border-color: #91d5ff;
          background-color: #e6f7ff;
        }

        &.Planejado {
          color: #722ed1;
          border-color: #d3adf7;
          background-color: #f9f0ff;
        }

        &.Pago {
          color: #52c41a;
          border-color: #b7eb8f;
          background-color: #f6ffed;
        }

        &.Previsto {
          color: #faad14;
          border-color: #ffe58f;
          background-color: #fffbe6;
        }

        &.Estornado {
          color: #f5222d;
          border-color: #ffa39e;
          background-color: #fff1f0;
        }

        &.Confirmando {
          color: #95ab0a;
          border-color: #d4dc0d;
          background-color: #fffbe6;
        }

        &.Falhou {
          color: #f5222d;
          border-color: #ffa39e;
          background-color: #fff1f0;
        }

        &.Cancelado {
          color: #f5222d;
          border-color: #ffa39e;
          background-color: #fff1f0;
        }
      }
    }
  }
}
