@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.button-modal {
  font-family: $hanken_regular;
  font-size: pxToRem(16px);
  padding: 15px;
  border-radius: 0;
  height: 40px;
}

input,
.ant-select-selector {
  height: 40px;
  font-family: $hanken_regular;
  border-radius: 0 !important;
}

textarea {
  border-radius: 0 !important;
}

.ant-form-item-label {
  label {
    display: block;
    margin-bottom: 500px;
    font-family: $hanken_regular;
    font-size: pxToRem(18px) !important;
  }
}

.ant-alert-banner {
  border: unset;
}

.form-modal {
  width: 90%;

  .small-form {
    width: 200px;
  }

  .textarea-form {
    width: 100%;

    @include breakpoint(desktop-medium) {
      width: 50%;
    }

    textarea {
      height: 100px;
    }
  }

  .required {
    label {
      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}

.ticket-modal {
  @include breakpoint(desktop-medium) {
    min-width: 920px;
  }

  .ant-modal-content {
    padding: 40px;
    box-sizing: border-box;
  }

  .ant-modal-title {
    font-family: $hanken_medium;
    font-size: pxToRem(24px);
  }

  .service-fee {
    border: 1px solid #ffe58f !important;
    font-size: 14px;
    margin: 25px 0 40px;
    width: 90%;
  }

  .ant-radio-group {
    display: flex;
    gap: 10px;
  }
}
