@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalFadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.InfoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content-info {
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 10px;
    width: 80%;
    height: fit-content;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    transition: visibility 0s ease 0.3s;
    animation: modalFadeOut 0.3s ease forwards;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      animation: modalFadeIn 0.3s ease forwards;
    }

    @include breakpoint(tablet) {
      width: 80%;
      height: fit-content;
      max-width: 900px;
      // width: 100%;
    }

    p {
      padding: 10px 0 0 10px;
      font-size: 20px;
      font-family: $roboto_bold;
    }

    .image-modal {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      padding: 0 10px 0 0;
      cursor: pointer;
    }

    .infos-modal {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      padding: 0 15px;
      box-sizing: border-box;

      .title-modal {
        font-family: $roboto_bold;
        font-size: 15px;
        margin: 0;
        color: rgb(25, 31, 40);

        @include breakpoint(tablet-large) {
          font-size: 25px;
        }
      }

      div {
        p {
          font-size: 14px;
          color: rgb(76, 87, 108);
          text-align: center;
          font-family: $roboto_regular;

          @include breakpoint(tablet-large) {
            font-size: 18px;
            margin: 30px 20px;
          }
        }
      }

      .button-return {
        align-items: center;
        background-color: rgb(0, 151, 255);
        border-color: rgb(0, 151, 255);
        border-radius: 8px;
        border-style: solid;
        border-width: 1px;
        color: white;
        cursor: pointer;
        display: flex;
        font-family: $roboto_bold;
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
        line-height: 16px;
        min-width: fit-content;
        letter-spacing: 1px;
        outline: none;
        padding: 12px 16px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.2s ease-in;
        width: 100%;

        @include breakpoint(tablet-large) {
          background-color: white;
          color: rgb(0, 151, 255);
          width: max-content;
        }

        &:hover {
          color: white;
          background-color: rgb(0, 151, 255);
        }
      }
    }
  }
}
