@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.Summary {
  width: 100%;
  margin: 0;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @include breakpoint(desktop-small) {
    padding: 20px;
  }

  h2 {
    text-align: center;
    color: #4caf50;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: $hanken_regular;
  }

  h3 {
    color: #2c3e50;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: $hanken_regular;
  }

  p {
    color: #333;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 1.5;
    font-family: $hanken_regular;
  }

  .section {
    margin-bottom: 15px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;

    &.one {
      margin-top: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .section-header {
      font-weight: bold;
      color: #4caf50;
      margin-bottom: 10px;
    }

    .section-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .field {
        margin-bottom: 10px;

        .label {
          font-weight: bold;
          color: #2c3e50;
        }

        .value {
          color: #333;
          font-size: 16px;
        }
      }
    }
  }
}
