@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.Login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  .container-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .login-form-button {
      border-radius: 2px;
      background-color: #873800;
      font-family: $hanken_regular;

      &:hover {
        background-color: #873800;
        opacity: 0.8;
      }
    }

    .ant-btn-sm {
      color: #873800;
      font-family: $hanken_regular;
      font-size: pxToRem(17px);

      &:hover {
        color: #873800;
        opacity: 0.8;
      }
    }
  }

  .login-form {
    .ant-form-item-required {
      &::before {
        display: none !important;
      }
    }

    .ant-form-item {
      font-family: $hanken_medium;

      .ant-form-item-row {
        .ant-input-affix-wrapper {
          border-radius: 2px;
        }
      }
    }
  }

  .form-container {
    width: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    scrollbar-width: thin;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    @include breakpoint(desktop-small) {
      min-width: 460px;
      max-width: 500px;
      padding: 80px;
    }

    .logo {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;

      img {
        height: 85px;
      }
    }

    .form {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }

    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      button {
        width: 100%;
        font-family: $hanken_medium;
        color: black;
      }

      .ant-btn-default {
        border-radius: 2px;
        height: 32px;

        &:hover {
          background-color: white;
          opacity: 0.8;
          border-color: black;
          color: black;
        }
      }

      .ant-btn-text {
        font-size: pxToRem(16px);
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .background {
    width: 100%;
    // background-color: #ffe3b6;
    background-image: url("../../../assets/images/bg-login.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    justify-content: end;
    align-items: flex-end;
    padding: 40px;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }

    span {
      color: white;
      font-size: 25px;
      width: 290px;
      text-align: right;
    }
  }
}
