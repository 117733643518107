@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.Events {
  .breadcrumb-title {
    span {
      color: rgba(0, 0, 0, 0.45) !important;
      font-size: pxToRem(15px) !important;
      font-family: $hanken_regular !important;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px) !important;
      }
    }
  }

  .container-empty-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    h2 {
      font-family: $hanken_medium;
      font-size: pxToRem(22px);

      @include breakpoint(desktop-small) {
        font-size: pxToRem(32px);
      }
    }

    .description-empty {
      font-family: $hanken_regular;
      font-size: pxToRem(15px);
      max-width: 479px;
      text-align: center;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px);
      }
    }

    .container-empty-buttons {
      padding-top: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-family: $hanken_italic;
        font-size: pxToRem(18px);

        @include breakpoint(desktop-small) {
          font-size: pxToRem(20px);
        }
      }

      .empty-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        gap: 20px;
        margin-top: 24px;
        flex-direction: column;

        @include breakpoint(desktop-small) {
          flex-direction: row;
        }

        button {
          width: 100%;
          display: flex;
          cursor: pointer;
          padding: 16px 24px;
          align-items: center;
          gap: 8px;
          border-radius: 0px 2px 2px 0px;
          border: 1px solid #d9d9d9;
          background: #fff;
          font-family: $hanken_regular;
          font-size: pxToRem(17px);

          @include breakpoint(desktop-small) {
            font-size: pxToRem(20px);
            padding: 16px 40px;
            width: 50%;
          }

          &:first-child {
            width: 100%;

            @include breakpoint(desktop-small) {
              width: 60%;
            }
          }

          &:hover {
            // filter: brightness(1.2);
            opacity: 0.7;
          }
        }
      }
    }
  }

  .top-container-events {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 20px;
    flex-direction: column;
    width: 100%;

    @include breakpoint(desktop-small) {
      flex-direction: row;
      gap: 40px;
    }

    .close-alert {
      width: 100%;
      max-width: 890px;
    }

    .alert-success {
      width: 100%;
      max-width: 890px;
      padding: 16px;
      border-radius: 8px;
      background: #b0fdd0;
      color: #012d1b;
      font-family: $hanken_regular;
      font-size: pxToRem(16px);

      span {
        &.ant-alert-icon {
          svg {
            width: 24px;
            height: 24px;
            path {
              fill: #012d1b;
            }
          }
        }
      }
    }

    .buttons-create-event {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: unset;
      right: 0;
      top: -40px;
      gap: 8px;
      height: 60px;

      @include breakpoint(desktop-small) {
        position: relative;
      }

      p {
        font-family: $hanken_regular;
        font-size: pxToRem(18px);
        margin: 0;
      }

      .buttons {
        display: flex;
        gap: 26px;

        button {
          width: 100%;
          display: flex;
          cursor: pointer;
          padding: 10px 14px;
          align-items: center;
          gap: 8px;
          border-radius: 0px 2px 2px 0px;
          border: 1px solid #d9d9d9;
          background: #fff;
          font-family: $hanken_regular;
          font-size: pxToRem(17px);

          @include breakpoint(desktop-small) {
            font-size: pxToRem(16px);
            white-space: nowrap;
            padding: 10px 40px;
            width: 50%;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          &:first-child {
            width: 100%;

            @include breakpoint(desktop-small) {
              width: 60%;
            }
          }

          &:hover {
            // filter: brightness(1.2);
            opacity: 0.7;
          }
        }
      }
    }
  }

  .container-events-show {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content: center;

    @include breakpoint(desktop-small) {
      justify-content: flex-start;
    }

    .container-event {
      display: flex;
      width: 280px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid#D9D9D9;
      background: #fff;

      .event-title {
        padding: 15px 20px;
        font-family: $hanken_medium;
        font-size: pxToRem(18px);
        height: 60px;
        margin: 0;
      }

      .container-event-info {
        width: 100%;
      }
      .event-description {
        padding: 15px 20px;
        font-family: $hanken_medium;
        font-size: pxToRem(18px);
        height: 70px;
        margin: 0;
      }

      .container-event-image {
        cursor: pointer;
        height: 150px;
        position: relative;
        transition: all 0.3s ease-in-out;
        width: 100%;

        &:hover {
          opacity: 0.6;

          button {
            opacity: 0.6;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        .button-container {
          background-color: rgba(0, 0, 0, 0.366);
        }

        button {
          align-items: center;
          background-color: rgba(255, 255, 255, 0.83);
          border-radius: 50%;
          border: unset;
          display: flex;
          justify-content: center;
          height: 30px;
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          visibility: visible;
          width: 30px;

          svg {
            height: 20px;
            width: 25px;
          }
        }
      }

      .container-event-buttons {
        height: 50px;
        width: 100%;
        justify-content: space-between;
        padding: 12px 0px;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 0px 0px #f0f0f0 inset;

        button {
          background-color: transparent;
          border: unset;
          width: 50%;
          height: 100%;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &:first-child {
            box-shadow: -1px 0px 0px 0px #f0f0f0 inset;
          }

          &:nth-child(2) {
            box-shadow: -1px 0px 0px 0px #f0f0f0 inset;
          }

          svg {
            width: 25px;
            height: 20px;
          }
        }
      }
    }
  }
}
