@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.custom-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;

  .line {
    border: none;
    border-top: 2px solid #8c8c8c19;
    width: 100%;
    margin: 20px 0;
  }

  .step {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    width: 100px;
    margin: 0 10px;

    &-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;

      &.step-active {
        color: #1890ff;
      }

      &.step-finish {
        border-color: green;
        color: green;
      }
    }

    &-title {
      font-size: 14px;
      color: grey;
      text-wrap: nowrap;

      &.step-active-title {
        color: #1890ff;
      }

      &.step-finish {
        color: #389e0d;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 12px;
      left: 50%;
      height: 2px;
      width: calc(100% - 48px);
      background-color: #d9d9d9;
      z-index: -1;
    }

    &:last-child::after {
      display: none;
    }
  }
}

.transfer-modal {
  @include breakpoint(desktop-medium) {
    min-width: 920px;
  }

  .ant-modal-content {
    padding: 40px;
    box-sizing: border-box;
  }

  .ant-modal-title {
    font-family: $hanken_medium;
    font-size: pxToRem(24px);
    margin-bottom: 20px;
  }

  .body-transfer {
    .subtitle-body {
      margin: 40px 0 20px 0;

      h3 {
        color: #434343;
        font-family: $hanken_regular;
        font-size: pxToRem(20px);
        margin: 0;
      }

      p {
        color: #434343;
        font-family: $hanken_regular;
        font-size: pxToRem(16px);
      }
    }

    .two-body-inputs {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .ant-form-item {
        width: 100%;
      }

      .type-hidden {
        .ant-form-item-label {
          visibility: hidden;
        }
      }
    }

    .three-body-inputs {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 50px;

      .ant-select {
        height: 40px;
        width: 100%;
      }

      .ant-form-item {
        width: 40%;
      }
    }
  }

  .transfer-option {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;

    span {
      font-family: $hanken_regular;
      font-size: pxToRem(16px);
    }
  }

  .date-picker-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 140px;
    height: 35px;

    .ant-picker {
      flex-grow: 1;
      height: 40px;
      border-radius: 8px;
      color: #007aff;
      font-family: $hanken_regular;
      background-color: #7878801f;
    }

    .calendar-icon {
      color: #1890ff;
      font-size: 20px;
    }
  }

  .resume {
    h3 {
      font-size: pxToRem(20px);
      font-family: $hanken_regular;
      font-weight: 400;
      color: #434343;
    }

    .subtitle {
      font-size: pxToRem(16px);
      font-family: $hanken_regular;
      font-weight: 400;
      color: #434343;
    }

    .confirmation-card {
      margin: 0 auto;
      background-color: #f9f9f9;
      padding: 80px 0 40px;
      border-radius: 12px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .subtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 16px;
      }

      .info-card {
        border-radius: 8px;

        .info-row {
          display: flex;
          justify-content: space-between;
          padding: 8px 25px;
          box-sizing: border-box;

          &:first-child {
            padding-top: 50px;
            border-top: 4px dashed #e0e0e0;
          }

          &:last-child {
            border-bottom: none;
          }

          .label {
            font-weight: 600;
            color: #333;
            font-family: $hanken_medium;
            font-size: pxToRem(18px);
          }

          .value {
            font-size: pxToRem(18px);
            font-family: $hanken_regular;
            color: #333;
          }
        }
      }
    }
  }

  .required {
    label {
      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}

.container-buttons {
  button {
    border-radius: 0 !important;
    font-size: pxToRem(16px);
    font-family: $hanken_regular;
  }
}
