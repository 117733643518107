@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/function.scss";

.Home {
  .content-dashboard {
    padding: 23px 10px 70px 10px;

    @include breakpoint(desktop-medium) {
      padding: 23px 32px 100px 32px;
    }
  }

  .bread-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    .space-dates {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      p {
        color: rgba(0, 0, 0, 0.45);
        font-family: $hanken_regular;
        font-size: pxToRem(15px);

        @include breakpoint(desktop-small) {
          font-size: pxToRem(20px);
        }
      }

      @include breakpoint(mobile-larger) {
        align-items: center;
        flex-direction: row;
        margin: 0;
      }
    }
  }
  .breadcrumb-title {
    span {
      color: rgba(0, 0, 0, 0.45) !important;
      font-size: pxToRem(15px) !important;
      font-family: $hanken_regular !important;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px) !important;
      }
    }
  }

  .container-empty-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    h2 {
      font-family: $hanken_medium;
      font-size: pxToRem(22px);

      @include breakpoint(desktop-small) {
        font-size: pxToRem(32px);
      }
    }

    .description-empty {
      font-family: $hanken_regular;
      font-size: pxToRem(15px);
      max-width: 479px;
      text-align: center;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px);
      }
    }

    .container-empty-buttons {
      padding-top: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-family: $hanken_italic;
        font-size: pxToRem(18px);

        @include breakpoint(desktop-small) {
          font-size: pxToRem(20px);
        }
      }

      .empty-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        gap: 20px;
        margin-top: 24px;
        flex-direction: column;

        @include breakpoint(desktop-small) {
          flex-direction: row;
        }

        button {
          width: 100%;
          display: flex;
          cursor: pointer;
          padding: 16px 24px;
          align-items: center;
          gap: 8px;
          border-radius: 0px 2px 2px 0px;
          border: 1px solid #d9d9d9;
          background: #fff;
          font-family: $hanken_regular;
          font-size: pxToRem(17px);

          @include breakpoint(desktop-small) {
            font-size: pxToRem(20px);
            padding: 16px 40px;
            width: 50%;
          }

          &:first-child {
            width: 100%;

            @include breakpoint(desktop-small) {
              width: 60%;
            }
          }

          &:hover {
            // filter: brightness(1.2);
            opacity: 0.7;
          }
        }
      }
    }
  }

  .container-dashboard {
    padding: 107px 0px 0;
    box-sizing: border-box;

    @include breakpoint(desktop-medium) {
      padding: 107px 50px 0;
    }

    .top-dashboard {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 35px;

      .card-top {
        display: flex;
        width: 100%;
        height: 119px;
        padding: 16px 31px;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #bfbfbf;

        @include breakpoint(desktop-medium) {
          width: 357px;
          height: 119px;
          padding: 16px 31px;
        }

        &.clock {
          width: 100%;
          padding-right: 50px;
          flex-direction: column;
          height: max-content;
          align-items: flex-start;

          @include breakpoint(desktop-medium) {
            flex-direction: row;
            align-items: center;
            height: 119px;
            width: max-content;
          }
        }

        .container-infos {
          &.clock {
            display: flex;
            align-items: center;

            .clock-title {
              color: #546b84;
              font-family: $hanken_bold;
              font-size: pxToRem(32px);
              margin: 0 0 0 10px;
            }
          }
          span {
            color: #595959;
            font-family: $hanken_regular;
            font-size: pxToRem(20px);
            font-weight: 500;
          }

          p {
            margin: 0;
          }

          .money {
            color: #3aaf7f;
            font-family: $hanken_bold;
            font-size: pxToRem(32px);
          }

          .ticket {
            color: #ff9d00;
            font-family: $hanken_bold;
            font-size: pxToRem(32px);
          }

          .clock-description {
            color: #8c8c8c;
            font-family: $hanken_italic;
            font-size: pxToRem(16px);
            font-weight: 400;
            margin-top: 6px;
          }
        }
      }
    }

    .body-dashboard {
      display: flex;
      margin-top: 150px;
      gap: 40px;
      flex-wrap: wrap;

      @include breakpoint(desktop-medium) {
        gap: 20px;
        flex-wrap: nowrap;
      }

      .card-rechart {
        border-radius: 8px;
        border: 1px solid #bfbfbf;
        width: 100%;
        height: 526px;
        flex-shrink: 0;
        padding: 16px 37px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @include breakpoint(desktop-medium) {
          max-width: 619px;
          height: 526px;
        }

        .recharts-legend-wrapper {
          display: none;
        }

        .title-card {
          color: #434343;
          font-family: $hanken_regular;
          font-size: pxToRem(20px);
          margin: 10px 0;
        }
      }
    }
  }
}
