@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.CheckoutTicket {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow-y: scroll;

  .container-checkout-ticket {
    width: 100%;
    border-bottom: 1px solid rgb(245, 247, 248);

    .container-checkout-ticket-box {
      padding: 24px;
      max-width: 1200px;
      margin: 0px auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-direction: column;

      @include breakpoint(tablet-large) {
        padding: 32px;
        flex-direction: row;
      }
    }

    .title-checkout {
      flex-direction: column;
      display: flex;
      width: auto;

      @include breakpoint(tablet-large) {
        margin-right: 20px;
        width: 66.6667%;
      }

      h1 {
        font-family: $roboto_bold;
        font-size: 30px;
        min-height: 35px;
        text-overflow: ellipsis;
        letter-spacing: 0.36px;
        margin: 0 0 10px 0;
        color: black;

        @include breakpoint(tablet-large) {
          font-size: 40px;
        }
      }

      .event-information-container {
        position: relative;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        margin-top: 8px;

        div {
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: hidden;
          flex-wrap: wrap;

          .title {
            font-family: $roboto_regular;
            font-size: 14px;
            line-height: 1.33;
            color: black;
            margin: 0 0 5px 0;
            width: 100%;
            text-align: left;

            @include breakpoint(tablet-large) {
              font-size: 18px;
            }
          }

          .info {
            font-family: $roboto_light;
            font-size: 14px;
            line-height: 1.43;
            color: black;
            width: 100%;
            margin: 0;
            text-align: left;
          }
        }

        .mdi--clock-outline {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }

        .carbon--location {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23000' d='M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3'/%3E%3Cpath fill='%23000' d='m16 30l-8.436-9.949a35 35 0 0 1-.348-.451A10.9 10.9 0 0 1 5 13a11 11 0 0 1 22 0a10.9 10.9 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 1 0-18 0a8.9 8.9 0 0 0 1.813 5.395'/%3E%3C/svg%3E");
          background-color: currentColor;
          -webkit-mask-image: var(--svg);
          mask-image: var(--svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
        }
      }
    }

    .ticket-result {
      width: auto;
      top: 0px;
      left: 0px;
      z-index: 3;

      @include breakpoint(tablet-large) {
        position: relative;
        width: 33.3333%;
        max-width: 344px;
      }

      .ticket-result-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        @include breakpoint(tablet-large) {
          margin-top: 0;
          position: fixed;
          max-width: 344px;
        }
      }

      .ticket-result-header {
        width: 100%;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(203, 203, 207);
        border-radius: 10px;

        @include breakpoint(tablet-large) {
          position: relative;
        }

        .header {
          background-color: rgb(76, 87, 108);
          color: rgb(255, 255, 255);
          padding: 12px 16px;
          display: flex;
          box-sizing: border-box;

          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          p {
            font-size: 18px;
            font-family: $roboto_bold;
            font-weight: 700;
          }
        }

        .ticket-result-items {
          width: 100%;

          .ticket-item {
            border-width: 0px 0px 1px;
            border-style: solid;
            border-color: rgb(203, 203, 207);
            padding: 12px 16px;
            box-sizing: border-box;
            font-size: 11px;
            display: flex;
            width: 100%;

            div {
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              width: 100%;
              box-sizing: border-box;

              .ticket-name {
                font-family: $roboto_bold;
                line-height: 1.5;
                font-size: 15px;
                word-break: break-word;
                color: rgb(76, 87, 108);
                flex: 0.8 1 0%;
                text-transform: uppercase;
              }

              .price {
                font-family: $roboto_regular;
                line-height: 1.3;
                color: rgb(76, 87, 108);
                font-size: 16px;
              }
            }

            &.fix {
              div {
                .ticket-name {
                  font-size: 18px;
                }
                .price {
                  font-family: $roboto_bold;
                  font-size: 18px;
                }
              }
            }

            &:last-child {
              border: unset;

              div {
                .ticket-name {
                  text-transform: unset;
                }
              }
            }
          }
        }
      }

      .ticket-time {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(245, 247, 248);
        border-radius: 4px;
        padding: 12px 16px;
        width: fit-content;

        .time-current {
          display: flex;
          align-items: center;
          margin-right: 16px;

          svg {
            fill: rgb(0, 151, 255);
            margin-right: 8px;
          }

          p {
            color: black;
            font-family: $roboto_bold;
            font-size: 18px;
          }
        }

        p {
          color: rgb(76, 87, 108);
          font-size: 12px;
          letter-spacing: 0.3px;
          line-height: 14px;
          font-family: $roboto_regular;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .container-infos {
    padding: 32px;
    max-width: 1200px;
    margin: 0px auto;
    padding-bottom: 150px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet-large) {
      flex-direction: row;
    }

    .container-infos-box {
      flex-direction: column;
      display: flex;
      width: auto;

      @include breakpoint(tablet-large) {
        margin-right: 20px;
        width: 66.6667%;
      }

      .title-checkout {
        font-size: 25px;
        font-family: $roboto_bold;

        @include breakpoint(tablet-large) {
          font-size: 40px;
        }
      }

      .container-data {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-direction: column;

        @include breakpoint(tablet-large) {
          flex-direction: row;
        }

        .row {
          display: flex;
          flex-direction: column;
          width: auto;
          gap: 20px;

          @include breakpoint(tablet-large) {
            width: 50%;
          }

          .field {
            width: 100%;
            padding: 0;

            .title {
              font-family: $roboto_bold;
              color: rgb(76, 87, 108);

              &::before {
                display: inline-block;
                margin-inline-end: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
              }
            }

            input {
              &:focus {
                border-color: rgba(0, 153, 255, 0.749);
                outline: none;
              }
            }
          }
        }
      }

      .ballon {
        box-sizing: border-box;
        width: 100%;
        margin-top: 20px;
        text-align: left;
        background-color: rgb(255, 255, 255);
        color: rgb(25, 31, 40);
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          transform: rotate(45deg);
          background-color: rgb(255, 255, 255);
          border-color: rgb(203, 203, 207);
          border-style: solid;
          border-width: 1px 0px 0px 1px;
          top: -8px;
          left: 20px;
        }

        .container-ballon {
          line-height: 24px;
          font-size: 18px;
          letter-spacing: 1px;
          font-family: $roboto_regular;
          border: 1px solid rgb(203, 203, 207);
          padding: 10px;
          border-radius: 4px;
          background-color: rgb(255, 255, 255);
          font-size: 13px;
          position: relative;
        }
      }

      h2 {
        font-family: $roboto_regular;
        font-size: 13px;

        @include breakpoint(tablet-large) {
          font-size: 20px;
        }
      }

      .button-continue {
        background-color: rgb(0, 151, 255);
        border-radius: 4px;
        color: white;
        font-family: $roboto_bold;
        font-size: 16px;
        padding: 12px 24px;
        border: unset;
        margin-top: 30px;
        cursor: pointer;

        @include breakpoint(tablet-large) {
          max-width: 300px;
        }

        &.pay {
          background-color: rgb(81, 168, 0);

          &:hover {
            background-color: rgb(81, 158, 0);
          }
        }

        &:hover {
          background-color: rgb(0, 140, 230);
        }
      }

      .infos-payment {
        h3 {
          font-family: $roboto_bold;
          font-size: 16px;
          color: black;
          margin-bottom: 10px;
          text-align: left;

          @include breakpoint(tablet-large) {
            font-size: 20px;
          }
        }

        .container-data {
          width: 100%;
          display: flex;
          gap: 20px;

          .row {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 20px;

            .field {
              width: 100%;
              padding: 0;

              .title {
                font-family: $roboto_bold;
                color: rgb(76, 87, 108);

                &::before {
                  display: inline-block;
                  margin-inline-end: 4px;
                  color: #ff4d4f;
                  font-size: 14px;
                  font-family: SimSun, sans-serif;
                  line-height: 1;
                  content: "*";
                }
              }

              input {
                &:focus {
                  border-color: rgba(0, 153, 255, 0.749);
                  outline: none;
                }
              }
            }
          }
        }

        .ballon {
          box-sizing: border-box;
          width: 100%;
          margin-top: 20px;
          text-align: left;
          background-color: rgb(255, 255, 255);
          color: rgb(25, 31, 40);
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            position: absolute;
            background-color: rgb(255, 255, 255);
            border-color: rgb(203, 203, 207);
            border-style: solid;
            border-width: 1px 0px 0px 1px;
            left: 20px;
          }

          .container-ballon {
            line-height: 24px;
            font-size: 18px;
            letter-spacing: 1px;
            font-family: $roboto_regular;
            border: 1px solid rgb(203, 203, 207);
            padding: 10px;
            border-radius: 4px;
            background-color: rgb(255, 255, 255);
            font-size: 13px;
            position: relative;
            margin: 10px 0px 25px;
          }
        }

        .payment-type-container {
          .payment-buttons {
            display: flex;
            flex-direction: column;
            padding-bottom: 24px;
            border-bottom: 1px solid rgb(221, 224, 228);

            .payment-type-button {
              display: flex;
              align-items: center;
              font-family: $roboto_bold;
              font-size: 18px;
              line-height: 1.5;
              gap: 10px;
              color: black;
              letter-spacing: 0.5px;

              padding: 16px;
              height: auto;
              border-radius: 8px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;

              .custom-radio {
                width: 20px; /* Largura do botão de rádio */
                height: 20px; /* Altura do botão de rádio */
                border-radius: 50%; /* Forma circular */
                border: 2px solid rgba(0, 153, 255, 0.749); /* Cor do botão de rádio */
                background-color: #fff;
                margin-right: 10px; /* Espaço entre o botão e o texto */
                position: relative;
                transition: background-color 0.3s ease;

                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 10px; /* Tamanho do círculo interno quando selecionado */
                  height: 10px;
                  border-radius: 50%;
                  background-color: #fff; /* Cor do círculo interno */
                  transform: translate(-50%, -50%);
                  transition: background-color 0.3s ease;
                }
              }

              &.active {
                background-color: rgb(245, 247, 248);

                .custom-radio {
                  background-color: rgba(
                    0,
                    153,
                    255,
                    0.749
                  ); /* Cor quando ativo */
                }
              }

              svg {
                min-width: 38px;
                height: 20px;
              }

              input[type="radio"] {
                display: none;
              }
              .tag-card {
                border-width: 1px;
                border-style: solid;
                border-radius: 24px;
                font-weight: 600;
                background-color: rgb(230, 244, 255);
                color: rgba(0, 153, 255, 0.749);
                // border-color: rgb(230, 244, 255);
                padding: 2px 12px;
                font-size: 13px;
                height: 19px;
                align-self: auto;
              }
            }
          }

          .payment-card {
            margin-top: 24px;
            width: 100%;
            border: 1px solid rgb(0, 151, 255);
            border-radius: 16px;
            overflow: hidden;
            min-height: 200px;

            .payment-header {
              display: flex;
              padding: 24px;
              background-color: rgb(245, 247, 248);
              border-radius: 16px 16px 0px 0px;
              height: 80px;
              box-sizing: border-box;
              align-items: center;
              flex: 1 1 0%;
              gap: 8px;

              .tag-payment {
                width: 35px;
                path {
                  fill: rgb(0, 151, 255);
                }
              }

              .info-header {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 2px;
                width: 100%;

                span {
                  font-family: $roboto_bold;
                  color: rgb(76, 87, 108);
                  font-size: 17px;
                  display: flex;
                  align-items: center;

                  &.title {
                    font-size: 20px;
                  }

                  &:last-child {
                    font-size: 14px;
                    color: rgb(0, 151, 255);
                    font-family: $roboto_regular;
                  }
                }
              }

              img {
                max-width: 60px;

                &.pix {
                  max-width: 200px;
                  object-fit: cover;
                }
              }
            }

            .payment-info-card {
              padding: 24px;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              gap: 24px;

              .field {
                padding: 0;
                width: 100%;

                @include breakpoint(tablet-large) {
                  width: 50%;
                }

                &.small {
                  width: 100%;

                  @include breakpoint(tablet-large) {
                    width: auto;
                  }
                }

                .title {
                  font-family: $roboto_bold;
                  color: rgb(76, 87, 108);

                  &::before {
                    display: inline-block;
                    margin-inline-end: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: "*";
                  }
                }

                input {
                  font-family: $roboto_regular;
                  letter-spacing: 0.5px;

                  &:focus {
                    border-color: rgba(0, 153, 255, 0.749);
                    outline: none;
                  }
                }

                &.date {
                  display: flex;
                }
              }

              .row {
                display: flex;
                gap: 24px;
                align-items: center;
                flex-direction: column;

                @include breakpoint(tablet-large) {
                  flex-direction: row;
                }
              }

              .divider {
                border-top: 1px solid rgb(221, 224, 228);
                display: block;
                margin: 32px 0px;
              }

              .container-installments {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .radio-group {
                  display: flex;
                  flex-direction: column;

                  .error {
                    font-size: 12px;
                    color: #e74c3c;
                  }

                  .radio-label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 16px;
                    color: rgb(76, 87, 108);

                    input[type="radio"] {
                      margin-right: 10px;
                      cursor: pointer;
                      accent-color: rgba(0, 153, 255, 0.749);
                    }

                    .option-text {
                      font-size: 16px;
                      font-family: $roboto_regular;

                      strong {
                        margin-left: 8px;
                        font-family: $roboto_light;
                        font-size: 11px;
                      }
                    }

                    &.selected {
                      font-weight: bold;
                      color: rgba(0, 153, 255, 0.749);
                    }
                  }
                }

                .info-box {
                  align-self: end;
                  padding: 16px 24px;
                  background-color: rgb(245, 247, 248);
                  color: rgb(76, 87, 108);
                  gap: 8px;
                  display: flex;
                  flex-direction: column;
                  border-radius: 8px;
                  line-height: 24px;
                  width: fit-content;
                  margin-top: 8px;

                  .price {
                    font-family: $roboto_bold;
                    font-size: 18px;
                    margin: 0;
                    color: rgb(76, 87, 108);
                  }

                  .details {
                    font-family: $roboto_regular;
                    font-size: 14px;
                    margin: 0;
                    color: rgb(76, 87, 108);

                    span {
                      font-size: 11px;
                    }
                  }
                }
              }

              .info-pix {
                span {
                  font-size: 19px;
                  line-height: 24px;
                  color: rgb(25, 31, 40);
                  font-family: $roboto_bold;
                }

                p {
                  color: rgb(76, 87, 108);
                  font-size: 17px;
                  margin-top: 8px;
                  margin-bottom: 24px;
                  font-family: $roboto_regular;
                }
              }
            }
          }
        }
      }
    }

    .right-box {
      width: auto;

      @include breakpoint(tablet-large) {
        width: 33.3333%;
        max-width: 344px;
      }
    }
  }
  .payment-success {
    border-radius: 8px;
    outline: none;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    font-size: 18px;
    font-family: $roboto_bold;
    line-height: 16px;
    margin-top: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    width: max-content;
    cursor: pointer;
    transition: 0.2s ease-in;
    text-decoration: none;
    background-color: rgb(81, 168, 0);
    color: rgb(255, 255, 255);
    padding: 16px 24px;
  }
}
