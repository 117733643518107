@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.TypeSelection {
  h2 {
    color: rgba(0, 0, 0, 0.45);
    font-size: pxToRem(24px);
    font-family: $roboto_regular;
    margin-bottom: 20px;
    margin: 0;
  }

  .container-buttons-type {
    width: 100%;
    display: flex;
    justify-content: center;

    .button-form {
      border-radius: 2px;
      background-color: #873800;
      font-family: $hanken_regular;

      &:hover {
        background-color: #873800 !important;
        opacity: 0.8;
      }
    }
  }
}
