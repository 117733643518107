@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.ticket-modal {
  .ant-modal-title {
    font-family: $hanken_medium;
    font-size: pxToRem(24px);
  }

  .button-add-question {
    padding: 15px;
    border-radius: 0;
    height: 40px;
    margin-top: 40px;

    span {
      font-family: $hanken_regular;
      font-size: pxToRem(16px) !important;
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .ant-card-body {
    padding: 0 !important;
  }

  .button-modal {
    font-family: $hanken_regular;
    font-size: pxToRem(16px);
    padding: 15px;
    border-radius: 0;
    height: 40px;
  }
}

.custom-delete-modal {
  max-width: 416px;

  button {
    font-family: $hanken_regular;
    font-size: pxToRem(16px);
    border-radius: 2px;
  }

  .ant-btn-color-primary {
    background-color: #1890ff;
  }

  .ant-modal-confirm-paragraph {
    span {
      font-family: $hanken_regular;
      font-weight: 500;
      font-size: pxToRem(20px);
    }
  }
}

.EventDetails {
  padding-bottom: 100px;

  .content-event {
    padding: 23px 10px 70px 10px;

    @include breakpoint(desktop-medium) {
      padding: 23px 32px 100px 32px;
    }
  }

  .title-and-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(desktop-medium) {
      margin-bottom: 80px;
      align-items: center;
      flex-direction: row;
    }

    .custom-btn {
      display: flex;
      align-items: center;
      padding: 16px;
      font-family: $hanken_regular;
      font-size: pxToRem(20px);
      color: #1890ff;
      border: 1px solid #1890ff;
      background-color: white;
      border-radius: 0;
      height: 56px;
      transition: background-color 0.3s ease;

      span {
        height: 30px;
      }

      .icon {
        margin-right: 10px;
        font-size: 18px;
        font-weight: bold;
      }

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .ticket-price {
    font-family: $hanken_regular !important;
    font-size: pxToRem(16px) !important;
    color: #1890ff !important;
  }

  .ticket-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    .button-ticket {
      cursor: pointer;
      width: 21px;
      height: 21px;
    }

    .button-ticket-completed {
      display: flex;
      cursor: pointer;
      padding: 0px 14px;
      height: 30px;
      align-items: center;
      gap: 8px;
      width: 200px;
      border-radius: 100px;
      border: 1px solid #d9d9d9;
      font-family: "Roboto";
      font-size: pxToRem(14px);
      background: #fff;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @include breakpoint(tablet) {
    padding-bottom: 200px;
  }

  .divider {
    border-top: 4px dashed #cbcbcb;
    padding: 10px 0;
    position: relative;
    width: 100%;
    background-color: white;

    @include breakpoint(desktop-medium) {
      padding: 50px 0;
    }
  }

  .type-event-info {
    // position: absolute;
    display: flex;
    padding: 12px 63px;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 1.25rem 0;
    width: max-content;
    border-radius: 16px 16px 0px 0px;
    background: #ff9d00;

    p {
      margin: 0;
      font-family: $hanken_bold;
      font-size: pxToRem(20px);

      @include breakpoint(desktop-small) {
        font-size: pxToRem(30px);
      }
    }
  }
  .breadcrumb-title {
    span {
      font-size: pxToRem(15px) !important;
      font-family: $hanken_regular !important;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(20px) !important;
      }
    }
  }

  .title {
    padding: 20px 10px 36px;

    @include breakpoint(desktop-medium) {
      padding: 104px 10px 36px;
    }

    @include breakpoint(desktop-large) {
      padding: 104px 40px 36px;
    }

    @include breakpoint("4k") {
      padding: 104px 70px 36px;
    }
    p {
      font-family: $hanken_bold;
      font-size: pxToRem(24px);
      color: black;
    }
  }

  label {
    &.required {
      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }

  .CreateEvent-content {
    display: flex;
    padding: 0 10px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 88px;
    position: relative;

    @include breakpoint(desktop-medium) {
      padding: 0 10px 150px;
    }

    label {
      font-family: $hanken_regular;
      font-size: pxToRem(18px);
    }

    input,
    .ant-select {
      height: 40px;
      border-radius: 0 !important;
    }

    @include breakpoint(desktop-large) {
      padding: 0 40px 50px;
    }

    @include breakpoint("4k") {
      padding: 0 70px 50px;
    }

    .left {
      width: 100%;

      @include breakpoint(desktop-medium) {
        width: 80%;
      }

      &.completed {
        width: 100%;
      }

      .image-response {
        @include breakpoint(desktop-medium) {
          display: none;
        }
      }

      .switch-map {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font-family: $hanken_regular;
          font-size: pxToRem(16px);
        }
      }

      .date-time-pickers {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        .date-time-group {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 45%; // Garantir que os grupos tenham a largura correta

          .ant-form-item {
            width: 100%;
          }

          .date-time-field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: max-content;

            .custom-icon {
              font-size: 25px;
              color: #1890ff;
              margin-right: 10px;
            }

            .ant-picker {
              border: none;
              background: none;
              box-shadow: none;

              &:focus,
              &:hover {
                border-color: transparent;
                box-shadow: none;
              }
            }

            .ant-picker-clear {
              left: 80%;
            }

            .ant-picker-input > input {
              color: #1890ff;
              background-color: #f5f5f5;
              padding: 5px 0 5px 10px;
              border-radius: 6px !important;
              box-sizing: border-box;
              width: max-content;
              font-family: $hanken_medium;
              font-size: pxToRem(16px);
            }

            .ant-picker-suffix {
              display: none; // Esconder o ícone padrão do antd
            }
          }
        }
      }

      .event-form-row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include breakpoint(desktop-medium) {
          flex-direction: row;
        }

        .ant-form-item {
          flex: 1;

          &:not(:last-child) {
            @include breakpoint(desktop-medium) {
              margin-right: 20px;
            }
          }
        }
      }

      .event-form {
        .ant-form-item-label > label {
          font-weight: 500;
        }

        .ant-input,
        .ant-select,
        .ant-picker {
          border-radius: 4px;
        }

        .ant-btn-primary {
          background-color: #1890ff;
          border-color: #1890ff;
          width: 100%;
        }
      }

      .empty-tickets {
        margin: 83px 0 110px 0;

        .custom-btn {
          display: flex;
          align-items: center;
          padding: 16px;
          font-family: $hanken_regular;
          font-size: pxToRem(20px);
          color: #1890ff;
          border: 1px solid #1890ff;
          background-color: white;
          border-radius: 0;
          height: 56px;
          transition: background-color 0.3s ease;

          span {
            height: 30px;
          }

          .icon {
            margin-right: 10px;
            font-size: 18px;
            font-weight: bold;
          }

          &:hover {
            opacity: 0.8;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .container-width {
      .jodit-react-container {
        margin-top: 10px;
        min-height: 200px !important;

        @include breakpoint(desktop-medium) {
          min-height: 471px !important;
        }

        .jodit-container {
          .jodit-wysiwyg {
            min-height: 200px !important;

            @include breakpoint(desktop-medium) {
              min-height: 471px !important;
            }
          }
        }
      }
    }

    .divider {
      border-top: 4px dashed #cbcbcb;
      margin: 20px 0;
      position: relative;
      width: 100%;
      left: 0;

      @include breakpoint(desktop-medium) {
        margin: 100px 0;
      }
    }

    .title-container {
      p {
        font-family: $hanken_bold;
        font-size: pxToRem(24px);
        color: black;
      }
    }

    .event-form-location {
      display: flex;
      flex-direction: column;
      gap: 36px;

      .distribution-three {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include breakpoint(desktop-medium) {
          gap: 36px;
          flex-direction: row;
        }

        .ant-form-item {
          &:first-child {
            @include breakpoint(desktop-medium) {
              width: 20%;
            }
          }

          &:nth-child(2) {
            @include breakpoint(desktop-medium) {
              width: 80%;
            }
          }

          &:last-child {
            @include breakpoint(desktop-medium) {
              width: 20%;
            }
          }
        }
      }

      .distribution-two,
      .distribution-finish {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(desktop-medium) {
          gap: 36px;
          align-items: center;
          flex-direction: row;
        }

        .ant-form-item {
          width: 100%;
        }

        .edit-complement {
          font-size: pxToRem(14px);
          display: flex;
          align-items: center;
          width: max-content;

          @include breakpoint(desktop-medium) {
            margin-top: 10px;
          }

          p {
            width: max-content;
          }
        }
      }
    }

    .right {
      width: 40%;
      min-width: 300px;
      display: none;

      @include breakpoint(desktop-medium) {
        display: flex;
      }

      .image-response {
        display: none;
        width: 100%;

        @include breakpoint(desktop-medium) {
          display: flex;
        }
      }
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .button-create-close {
      font-family: $hanken_regular;
      font-size: pxToRem(20px);
      height: 62px;
      border: unset;
      background-color: unset;
      border-radius: 0;
      box-shadow: unset;

      &:hover {
        background-color: transparent;
      }
    }

    .button-create-event {
      font-family: $hanken_regular;
      font-size: pxToRem(20px);
      background-color: #1890ff;
      height: 62px;
      border-radius: 0;

      &:disabled {
        background-color: #f5f5f5;
        border: 1px solid #d9d9d9;
        color: rgba(0, 0, 0, 0.25);

        svg {
          path {
            fill: #b8b8b8;
          }
        }
      }
    }
  }
}

.jodit-add-new-line {
  display: none !important;
}
