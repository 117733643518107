@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.RegisterPerson {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    color: rgba(0, 0, 0, 0.45);
    font-size: pxToRem(24px);
    font-family: $roboto_regular;
    margin-bottom: 20px;
    margin: 0;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .container-inputs {
      width: 100%;

      @include breakpoint(desktop-small) {
        width: 50%;
      }
    }

    input,
    .ant-input-password-small {
      height: 40px;
      border-radius: 0 !important;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .button-form {
    border-radius: 2px;
    background-color: #873800;
    font-family: $hanken_regular;

    &:hover {
      background-color: #873800 !important;
      opacity: 0.8;
    }
  }
}
