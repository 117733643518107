@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.field {
  padding: 15px 30px 0 0;
  box-sizing: border-box;
  width: 100%;

  @include breakpoint(tablet) {
    width: 50%;
  }

  &.small {
    width: auto;
  }

  .title {
    font-family: $hanken_regular;
    font-size: 14px;
    margin: 0 0 5px 0;
    color: black;
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #e6e6f2;
    padding: 0 20px;
    font-size: 14px;
    color: #5e6977;
    box-sizing: border-box;
    border-color: #e1e8ee;

    &:focus {
      border: 2px solid black;
      outline: none;
    }

    &.valid {
      border-color: #7dc855;
      background-image: url("../../../assets/images/checkout/check.png") !important;
    }

    &.error {
      border-color: #e74c3c;
      background-image: url("../../../assets/images/checkout/remove.png") !important;
    }

    &.valid,
    &.error {
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: right 18px center;
    }
  }
}

.input-error-msg {
  display: block;
  font-size: 12px;
  color: #e74c3c;
}
