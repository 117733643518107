@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.Question {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2 2 300px;

  .input-box {
    display: flex;
    flex-direction: column;

    label {
      font-family: $roboto_regular;
      font-size: 16px;
      line-height: 1.5;
      color: black;
      margin-bottom: 10px;

      &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }

    input {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(221, 224, 228);
      border-radius: 4px;
      color: black;
      font-size: 16px;
      font-weight: 200;
      height: 48px;
      padding: 12px 16px;
      box-sizing: border-box;

      &:focus {
        border-color: rgb(0, 151, 255);
      }

      &.error {
        border-color: red;
      }

      &:focus {
        outline: 1px solid white;
      }
    }
    p {
      font-family: $roboto_regular;
      font-size: 16px;
      color: red;
      margin: 0;
    }
  }

  .Select {
    width: 100%;
  }
}
