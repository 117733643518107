@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";
@import "../../../../assets/scss/function.scss";

.StepsRegister {
  display: flex;
  width: 100%;
  height: 90vh;
  padding: 10px;
  box-sizing: border-box;
  gap: 30px;
  flex-direction: column;

  @include breakpoint(desktop-small) {
    flex-direction: row;
  }

  .container-steps {
    background-color: #873800;
    border-radius: 1rem;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(desktop-small) {
      padding: 30px;
      justify-content: flex-start;
      width: 300px;
      flex-direction: column;
    }

    .step {
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      .circle {
        width: 30px;
        height: 30px;
        background-color: #af4900;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #873800;
        border: 2px solid transparent;
        transition: all 0.3s ease;

        @include breakpoint(desktop-small) {
          width: 40px;
          height: 40px;
        }
      }

      .text {
        display: none;
        flex-direction: column;
        font-family: $hanken_regular;

        @include breakpoint(desktop-small) {
          display: flex;
        }

        .step-label {
          font-size: pxToRem(13px);
          font-family: $hanken_regular;
          text-transform: uppercase;
          opacity: 0.8;
        }

        .step-name {
          font-size: pxToRem(16px);
          font-family: $hanken_bold;
        }
      }

      &.active .circle,
      &.disabled .circle {
        background-color: #ffffff;
        color: #873800;
        border-color: #ffffff;
      }
    }
  }

  .container-registers {
    flex: 1;
    background-color: #ffffff;
    padding: 2rem;
    overflow-y: auto;
    border-radius: 1rem;
    color: #4f42c8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    h2 {
      color: rgba(0, 0, 0, 0.45);
      font-size: pxToRem(24px);
      font-family: $hanken_regular;
      margin-bottom: 20px;
      margin: 0;
    }

    .container-inputs-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 0 36px;
      flex-direction: column;

      @include breakpoint(desktop-small) {
        flex-direction: row;
      }

      .left,
      .right {
        width: 100%;

        @include breakpoint(desktop-small) {
          width: 50%;
        }
      }
    }

    .container-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .button-form {
      border-radius: 2px;
      background-color: #873800;
      font-family: $hanken_regular;
      font-size: pxToRem(18px);
      height: 40px;

      &:hover {
        background-color: #873800 !important;
        opacity: 0.8;
      }
    }

    label {
      font-family: $hanken_regular !important;
    }

    .switch-map {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0;

      .label-switch {
        font-size: pxToRem(18px);
        font-family: $hanken_regular;
      }
    }

    .event-form-location {
      display: flex;
      flex-direction: column;
      gap: 0 36px;
      width: 100%;

      @include breakpoint(desktop-small) {
        width: 60%;
      }

      .title-container {
        p {
          font-family: $hanken_bold;
          font-size: pxToRem(24px);
          color: black;
        }
      }

      .distribution-three {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include breakpoint(desktop-medium) {
          gap: 36px;
          flex-direction: row;
        }

        .ant-form-item {
          &:first-child {
            @include breakpoint(desktop-medium) {
              width: 20%;
            }
          }

          &:nth-child(2) {
            @include breakpoint(desktop-medium) {
              width: 80%;
            }
          }

          &:last-child {
            @include breakpoint(desktop-medium) {
              width: 20%;
            }
          }
        }
      }

      .distribution-two,
      .distribution-finish {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(desktop-medium) {
          gap: 36px;
          align-items: center;
          flex-direction: row;
        }

        .ant-form-item {
          width: 100%;
        }

        .edit-complement {
          font-size: pxToRem(14px);
          display: flex;
          align-items: center;
          width: max-content;

          @include breakpoint(desktop-medium) {
            margin-top: 10px;
          }

          p {
            width: max-content;
          }
        }
      }
    }
  }
}
